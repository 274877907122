<template>
    <Card class="card-custom h-100" :class="{ '_sel' : selectedStat === 'all_players' }">
        <template #title>
            <div class="d-flex">
                <div class="column-three">
                    <v-row class="box-column _dash">
                        <v-col class="d-flex align-center">
                          <div class="d-flex align-center justify-center box-icon">
                            <v-icon color="var(--white)">mdi-account-group</v-icon>
                          </div>
                        </v-col>
                      </v-row>
                </div>
                <div class="column-three">
                    <Knob valueColor="var(--main-flamingo)" readonly v-model="knobValue" />
                </div>
                <div class="column-three d-flex justify-end">
                    <div class="d-flex flex-column">
                      <v-chip dark color="var(--purple-500)">
                          {{ $i18n.t('online') }}: 5
                        </v-chip>
                      <v-chip dark class="mt-1" color="var(--purple-700)">
                          {{ $i18n.t('total') }}: 305
                        </v-chip>
                    </div>
                </div>
            </div>
        </template>
        <template #content>
            
            <Toolbar>
                <template #start>
                    <h4>
                        <span class="font-weight-bold">{{ $t('all_players') }}</span>
                    </h4>
                </template>
                <template #end>
                    <Tag severity="success" class="stats">
                        <span class="stats-value">+12%</span>
                        <v-icon class="stats-arrow">mdi-arrow-up</v-icon>
                    </Tag>
                  </template>
            </Toolbar>
        </template>
        <template #footer>
          <Button v-if="selectedStat !== 'all_players'" @click="toSelectStat()" 
          :disabled="loadingFlag" :loading="loadingFlag" 
          :label="$t('show_detailed_info')" 
          class="p-button-sm p-button-success fw" />
        </template>
      </Card>
</template>

<script>
    export default {
        name: 'StatsPlayers',
        data() {
            return {
                currentStat: 'all_players',
                knobValue: 50,
            }
        },
        props:{
            selectedStat: {
                type: String,
                required: true,
            },
            loadingFlag: {
                type: Boolean,
                required: true,
            },
        },
        methods:{
            toSelectStat(){
                this.$store.commit('dashboard/SET_SELECTED_STAT', this.currentStat)
            },
        },
    }
</script>

<style lang="scss">
</style>