<template>
    <div class="">
        <h3 class="text-left">
          {{ $t('by_date') }}
        </h3>
        <!-- @select="test" -->
        <Chart type="bar"  :data="dataGraph" :options="option" />
      </div>
</template>

<script>
    export default {
        name: 'ByDateInfoGraph',
        props:{
            dataGraph: {
                type: Object,
                required: true,
            },
            option: {
                type: Object,
                required: true,
            },
        },
        methods: {
            // test(event){
            //     console.log('event', event.element);
            // }
        },
    }
</script>

<style lang="scss" scoped>

</style>