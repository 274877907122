<template>
    <v-main>
        <div class="main-content">
          <Card class="card-custom">
            <template #content>
              <filters-dashboard class="mb-1" />
              <general-dashboard class="mb-1" /> 
              <v-row >
                <v-col xl="6" md="6" sm="12" cols="12" class="col">
                  <!-- Totals -->
                  <total-types-dashboard />
                </v-col>
                <v-col xl="6" md="6" sm="12" cols="12" class="col">
                  <!-- graphs & tables -->
                  <comparisons-dashboard/>  
                </v-col>
              </v-row>
              <!-- Graphs -->
              <!-- <all-chart /> -->
                <!-- Stats -->
                  <!-- <all-stats /> -->
                    <!-- Top Provider || Games -->
                  <top-dashboard class="mt-1" />
            </template>
          </Card>
        </div>
    </v-main>
</template>

<script>
import AllStats from './Stats/AllStats'
import AllChart from './Charts/AllChart'
import TotalTypesDashboard from './TotalTypesDashboard'
import GeneralDashboard from './GeneralDashboard'
import FiltersDashboard from './FiltersDashboard'
import DateRangePicker from '@owumaro/vue-date-range-picker'
import ComparisonsDashboard from './ComparisonsDashboard/ComparisonsDashboard'
import { mapGetters } from 'vuex';
import TopDashboard from './TopTen/TopDashboard.vue';
    export default {
      components:{
        TopDashboard,
        TotalTypesDashboard,
        ComparisonsDashboard,
        FiltersDashboard,
        GeneralDashboard,
        DateRangePicker,
        AllChart,
        AllStats,
      },
        name: 'Dashboard',
        data() {
            return {
                rangeMenu: false,
                value1: 50,
                value2: 30,
                value3: 70,
                selectedStat: 'all_players',
                isSelectedStat: true,
                loadingFlag: false,
                datesToFilter: [],
                filters:{
                  group_by: 'D',
                },
            //========================================================================================================================================================
            
            }
        },
        computed: {
            ...mapGetters({
                currencies: 'getCurrencies',
                payments: 'getPaymentWays',
            }),
            filteredCurrencies(){
                return this.currencies.filter(currency => currency !== 'All')
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm=>{
                vm.$store.commit('setPageName', 'dashboard')
            })
        },
        async mounted() {
            if (this.payments.length === 0 ) {
        await this.$store.dispatch('getPaymentWays');
      }
        },
        methods: {
          updateRanges: function(range){
            console.log(range);
          },
          getGraphTitle(title){
            switch (title) {
              case 'all_players':
                return this.$t('all_players');
              case 'all_deposits':
                
                return this.$t('deposits');
              case 'fin_trans':
                return this.$t('financial_transactions');
            
              default:
                break;
            }
          },
            toSelectStat(condition){
              //this.$refs.LineChart.reinit();
              //this.$refs.DonutChart.reinit();
              //this.$refs.lineChart.reinit();
                this.isSelectedStat = true;
                this.selectedStat = condition;
                if (condition === 'all_players') {
                    
                }
            },
            toGoManager(game){},
            applyFilters(){},
        },
    }
</script>

<style lang="scss" scoped>
.input-menu {
  position: relative;

  &__menu {
    display: flex;
    flex-direction: column;
    background-color: var(--surface-a);
  }
}



</style>