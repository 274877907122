import { render, staticRenderFns } from "./TopDashboard.vue?vue&type=template&id=1f2e5dcd&scoped=true"
import script from "./TopDashboard.vue?vue&type=script&lang=js"
export * from "./TopDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2e5dcd",
  null
  
)

export default component.exports