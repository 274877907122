<template>
    <v-row>
        <v-col class="column-three">
    
            <stats-players :selectedStat="selectedStat" :loadingFlag="loadingFlag" />
        </v-col>
        <v-col class="column-three">
            <stats-deposits :selectedStat="selectedStat" :loadingFlag="loadingFlag" />
    
        </v-col>
        <v-col class="column-three">
            <stats-transactions :selectedStat="selectedStat" :loadingFlag="loadingFlag" />
        </v-col>
        </v-row>
</template>

<script>
import StatsPlayers from './StatsPlayers'
import StatsDeposits from './StatsDeposits'
import StatsTransactions from './StatsTransactions'
    export default {
        components:{
            StatsPlayers,
            StatsDeposits,
            StatsTransactions,
        },
        name: 'AllStats',
        computed: {
            selectedStat(){
                return this.$store.state.dashboard.selectedStat;
            },
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>