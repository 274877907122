<template>
    <DataTable :value="topPlayers" 
            :rows="10"
            :loading="loadingFlag" 
            selectionMode="single" 
            dataKey="id"
        @row-select="toSelectedPlayer"
              stripedRows class="p-datatable-sm">
              <template #empty>
                {{ $t('no_data') }}
              </template>
              <Column field="user_avatar_preview" :header="$t('logo')" :styles="{ 'min-width': '5rem' }">
                <template #body="{data, index}">
                    <v-lazy v-model="isActive">
                    <div class="wrapper-item" :class="getClassByIndex(index)">
    
                            <v-img max-height="40px" max-width="40px" 
                            @load="isActive = true" 
                            :src="`https://minio.takemybet.pro/avatars/${data.user_avatar_preview}`" 
                            :alt="data.game_name" />
                            <span v-if="index < 3"> 
                                {{ index + 1 }}
                            </span>
                        </div>
                    </v-lazy>
                </template>
              </Column>
              <Column field="user_id" header="ID" :sortable="true" :styles="{ 'min-width': '10rem' }">
                <template #body="{data}">
                        <router-link :to="`/player/${data.user_id}`">{{ data.user_id }}</router-link>
                </template>
              </Column>
              <Column field="username" :header="$t('username')" :sortable="true" :styles="{ 'min-width': '10rem' }">
                <template #body="{data}">
                    <div class="d-flex align-center">
                        {{ data.username }}
                    </div>
                </template>
              </Column>
              <Column field="ggr_by_user_sum" :header="$t('ggr')" :sortable="true">
                <template #body="{data}">
                    <div class="d-flex align-center">
                        {{ `${data.ggr_by_user_sum} ${currency}` }}
                    </div>
                </template>
              </Column>
              <!-- <Column :exportable="false" :styles="{'min-width':'8rem'}">
                <template #body="{data}">
                    <div class="d-flex align-center">
                      <Button icon="pi pi-search" class="p-button-sm p-button-rounded p-button-warning" @click="toGoManager(data.game_name, data.provider_string_id)" />
                  
                    </div>
                  </template>
            </Column> -->
            </DataTable>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        name: 'TopPlayers',
        data() {
            return {
                isActive: false,
                selectedPlayer: null
            }
        },
        computed:{
            ...mapGetters({
                generalTop: 'dashboard/getDashboardTopTenInfo',
                currency: 'dashboard/getSelectedCurrency'
            }),
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
            topPlayers(){
                return this.generalTop.top_10_players;
            },
        },
        methods: {
            async toSelectedPlayer(info){
                await this.$router.push({path: `/player/${info.data.user_id}`})
            },
            getClassByIndex(index){
                if(index === 0){
                    return 'is-first'
                } else if (index === 1) {
                    return 'is-second'
                } else if(index === 2){
                    return 'is-third'
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.wrapper-item{
    background-color: var(--main-flamingo);
    border-radius: 6px;
    //overflow: hidden;
    padding: 3px;
    position: relative;
    display: flex;
    width: 46px;
    height: 46px;
    &.is-first{
        span{
            background-color: rgb(255, 153, 0);
        }
    }
    &.is-second{
        span{
            background-color: rgb(192, 192, 192);
        }
    }
    &.is-third{
        span{
            background-color: rgb(233, 119, 6);
        }
    }
    .v-image{
    }
    span{
        display: flex;
        justify-content:center;align-items: center;
        min-width: 18px;
      height: 18px;
      position: absolute;
      padding: 0 3px;
      border-radius: 50px;
      right: -5px;
      top: -3px;
      z-index: 2;
        color: var(--white);
    }
}
</style>