<template>
    <Card v-resize="onResize">
        <template #title>
          <div class="d-flex justify-start align-center">
            <span>{{$t('bar_graph_descr')}}</span>
            <v-spacer></v-spacer>
            <Button :label="$t('show_detailed_info')" class="p-button-sm p-button-success" />
          </div>
        </template>
        <template #content>
            <v-col md="10" cols="12" class="d-flex justify-start align-center pa-0">
              <v-row class="ml-0 text-uppercase">
                <v-col md="4" cols="12" class="d-flex align-center pa-0 pr-2">
                  <h2>100</h2>
                  <span>{{ $t('max') }}</span>
                </v-col>
                <v-col md="4" cols="12" class="d-flex align-center pa-0">
                  <h2>40</h2>
                  <span>{{ $t('min') }}</span>
                </v-col>
                <v-col md="4" cols="12" class="d-flex align-center pa-0">
                  <h2 class="c-red">80</h2>
                  <span>{{ $t('average') }}</span>
                </v-col>
              </v-row>
            </v-col>
          <Chart type="bar" ref="BarChart" :data="basicData" :options="basicOptions"/>
        </template>
      </Card>
</template>

<script>
    export default {
        name: 'DashLineChart',
        data() {
            return {
                basicData: {
                labels: [this.$t('jan'), this.$t('feb'), this.$t('mar'), this.$t('apr'), this.$t('may'), this.$t('jun'), 
                this.$t('jul'), this.$t('aug'), this.$t('sep'), this.$t('oct'), this.$t('nov'), this.$t('dec')
              ],
                datasets: [
                    {
                        label: this.$t('players'),
                        backgroundColor: '#8e24aa',
                        data: [95, 59, 80, 81, 56, 55, 40, 95, 59, 80, 81, 56]
                    },
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            }
        },
        mounted() {
          this.$refs.BarChart.reinit();
        },
        methods: {
          onResize(){
            this.$refs.BarChart.reinit();
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>