<template>
    <div class="">
        <h3 class="text-left">
          {{ $t('withdraws') }}
        </h3>
        <Chart type="line" :data="dataGraph" :options="option" />
      </div>
</template>

<script>
    export default {
        name: 'PaymentsWithdrawGraph',
        props:{
            dataGraph: {
                type: Object,
                required: true,
            },
            option: {
                type: Object,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>