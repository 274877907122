<template>
    <div>
        <h2 class="text-left">
            {{ $t('general') }}
        </h2>
        <div class="wrapper">
            <template v-if="!loadingFlag">
           <div class="wrapper-item" v-for="(info, index) in mergedTotalsData" :key="index">
                    <div class="item"  :class="getInfoProfit(info)">
                        <div class="item__content">
                            <div class="item__title">
                                {{ $t(info.title) }}
                            </div>
                                <Badge v-if="info.badgeText" 
                                value="?" severity="info" class="info-badge ml-1" v-tooltip.top="info.badgeText"></Badge>
                        </div>
                        <div class="item__footer">
                            <div class="item__value">
                                {{ info.currency ? `${info.value} ${info.currency}` : info.value }}
                            </div>
                            <div class="item__arrow" v-if="info.value !== 0">
                                <v-icon>{{ getArrowDirection(info) }}</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="wrapper-item" v-for="i in 8">
                    <Skeleton 
                    :key="i"
                    class="item" height="4rem"></Skeleton>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'GeneralDashboard',
        data() {
            return {
            }
        },
        computed:{
            ...mapGetters({
                totalsUsersInfo: 'dashboard/getDashboardTotalsUsersInfo',
                totalsPaymentsInfo: 'dashboard/getDashboardTotalsPaymentsInfo',
                totalsGamesInfo: 'dashboard/getDashboardTotalGamesInfo',
                getCurrency: 'dashboard/getSelectedCurrency'
            }),
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
            totalsUsersData() {
    const totalsUsersInfo = this.totalsUsersInfo;
    return Object.keys(totalsUsersInfo)
    .filter(key => key !== 'total_users_count'
    && key !== 'total_users_by_currency_count'

    )
    .map(key => {
      return {
        title: key,
        value: totalsUsersInfo[key]
      };
    });
  },
            totalsGamesData() {
    const totalsGamesInfo = this.totalsGamesInfo;
    return Object.keys(totalsGamesInfo)
    .map(key => {
      return {
        title: key,
        value: totalsGamesInfo[key],
        currency: this.getCurrency, 
      };
    });
  },
            totalsPaymentsData() {
    const totalsPaymentsInfo = this.totalsPaymentsInfo;
    const currency = totalsPaymentsInfo.currency;
    return Object.keys(totalsPaymentsInfo)
        .filter(key => key !== 'currency'
        && key !== 'total_users_balance_sum'
        && key !== 'total_users_bonus_balance_sum'

        ) 
        .map(key => {
            return {
                title: key,
                value: totalsPaymentsInfo[key],
                currency: currency 
            };
        });
  },
  mergedTotalsData() {
    const totalsUsersData = this.totalsUsersData;
    const totalsPaymentsData = this.totalsPaymentsData;
    const totalsGamesData = this.totalsGamesData;
    
    return [...totalsUsersData, ...totalsPaymentsData, ...totalsGamesData];
  }
        },
        methods:{
            getInfoProfit(item){
                const profit = item.value;
                // console.log(profit);
                if (profit > 0) {
                    return 'is-profit'
                } else if(profit < 0){ 
                    return 'is-expenses'
                }
            },
            getArrowDirection(item){
                const itemValue = item.value;
                if (itemValue > 0) {
                    return 'mdi-menu-up'
                } else {
                    return 'mdi-menu-down'

                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.wrapper{
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}
.wrapper-item{
    padding: 5px;
    display: flex;
    flex: 0 1 25%;
    @media (max-width:1100px){
          flex: 0 1 33.333%;
    }
    @media (max-width:992px){

          flex: 0 1 50%;
    }
    @media (max-width:768px){
          flex: 1 1 100%;
    }
}
.item{
    display: flex;
    flex-direction: column;
    width: 100%;
    //min-width: 300px;
    padding: 5px;
    padding-left: 8px;
    border: 1px solid var(--gray-500);
    border-left: 5px solid var(--gray-500);
    box-shadow: 0 0 5px rgba(125, 8, 187, 0.3);
    border-radius: 6px;
    //display: flex;
    //align-items: flex-start;
    //flex-direction: column;
    &.is-profit {
        border-color: var(--green-500);
        .v-icon{
            color: var(--green-500);
        }
    }
    &.is-expenses{
        border-color: var(--red-500);
        .v-icon{
            color: var(--red-500);
        }
    }
    &__content{
        text-align: left;
        flex: 1 1 100%;
    }
    &__title{

    }
    &__footer{
        display: flex;
        align-items: center;
        flex: 1 1 100%;
    }
    &__value{
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
        flex: 1 1 100%;
    }
    &__arrow{

    }
}
</style>