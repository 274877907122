<template>
    <Card class="card-custom h-100" :class="{ '_sel' : selectedStat === 'fin_trans' }">
        <template #title>
            <div class="d-flex">
                <div class="column-three">
                    <v-row class="box-column _dash">
                        <v-col class="d-flex align-center">
                          <div class="d-flex align-center justify-center box-icon">
                            <v-icon color="var(--white)">mdi-bank</v-icon>
                          </div>
                        </v-col>
                      </v-row>
                </div>
                <div class="column-three">
                    <Knob valueColor="var(--main-flamingo)" readonly v-model="knobValue" />
                </div>
                <div class="column-three d-flex justify-end">
                </div>
            </div>
        </template>
        <template #content>
            
            <Toolbar>
                <template #start>
                    <h4>
                        <span class="font-weight-bold">{{ $t('financial_transactions') }}</span>
                    </h4>
                </template>
                <template #end>
                    <Dropdown v-model="filters.dep_method" :options="payments"
                    optionValue="id" optionLabel="id"
                  :placeholder="$t('deposit_source')" :filter="true" :emptyFilterMessage="$t('no_data')">
                </Dropdown>
                  </template>
            </Toolbar>
        </template>
        <template #footer>
          <Button v-if="selectedStat !== 'fin_trans'" @click="toSelectStat()" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('show_detailed_info')" class="p-button-sm p-button-success fw" />
        </template>
      </Card>
</template>

<script>
import {mapGetters} from 'vuex'
    export default {
        name: 'StatsTransactions',
        data() {
            return {
                currentStat: 'fin_trans',
                knobValue: 70,
                filters:{
                    dep_method: '',
                },
            }
        },
        props:{
            selectedStat: {
                type: String,
                required: true,
            },
            loadingFlag: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapGetters({
                payments: 'getPaymentWays',
            }),
        },
        methods:{
            toSelectStat(){
                this.$store.commit('dashboard/SET_SELECTED_STAT', this.currentStat)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>