<template>
    <Card v-resize="onResize">
        <template #title>
          <div class="d-flex justify-start align-center">
            <span>{{$t('line_graph_descr')}}</span>
            <v-spacer></v-spacer>
            <Button :label="$t('show_detailed_info')" class="p-button-sm p-button-success" />
          </div>
        </template>
        <template #content>
          <v-col md="10" cols="12" class="d-flex justify-start align-center pa-0">
            <v-row class="ml-0 text-uppercase">
              <v-col md="4" cols="12" class="d-flex align-center pa-0 pr-2">
                <h2>100</h2>
                <span>{{ $t('max') }}</span>
              </v-col>
              <v-col md="4" cols="12" class="d-flex align-center pa-0">
                <h2>40</h2>
                <span>{{ $t('min') }}</span>
              </v-col>
              <v-col md="4" cols="12" class="d-flex align-center pa-0">
                <h2 class="c-red">80</h2>
                <span>{{ $t('average') }}</span>
              </v-col>
            </v-row>
          </v-col>
            <Chart type="line" ref="LineChart" :data="multiAxisData" :options="multiAxisOptions" />
        </template>
      </Card>
</template>

<script>
    export default {
        name: 'DashBarChart',
        data() {
            return {
            multiAxisData: {
              labels: [this.$t('jan'), this.$t('feb'), this.$t('mar'), this.$t('apr'), this.$t('may'), this.$t('jun'), 
                this.$t('jul'), this.$t('aug'), this.$t('sep'), this.$t('oct'), this.$t('nov'), this.$t('dec')],
                datasets: [{
                    label: this.$t('online'),
                    fill: false,
                    borderColor: '#FF6384',
                    yAxisID: 'y',
                    tension: .4,
                    data: [65, 59, 80, 81, 56, 55, 10, 80, 81, 56, 55, 10]
                }, 
                {
                    label: this.$t('banned'),
                    fill: false,
                    borderColor: '#36A2EB',
                    yAxisID: 'y1',
                    tension: .4,
                    data: [28, 48, 40, 19, 86, 27, 90,  48, 40, 19, 86, 48]
                },
                
                {
                    label: this.$t('new_p'),
                    fill: false,
                    borderColor: '#FFCE56',
                    yAxisID: 'y1',
                    tension: .4,
                    data: [86, 27, 90, 28, 48, 40, 19, 27, 90, 28, 48, 19]
                }
              ]
            },
            multiAxisOptions: {
                stacked: false,
                plugins: {
                    legend: {
                        labels: {
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                        },
                        grid: {
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                        },
                        grid: {
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: {
                        },
                        grid: {
                            drawOnChartArea: false,
                        }
                    }
                }
              },
            }
        },
        mounted() {
          this.$refs.LineChart.reinit();
        },
        methods: {
          onResize(){
            this.$refs.LineChart.reinit();
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>