<template>
    <div>
        <SelectButton :disabled="loadingFlag" 
        v-model="filtersDashboard"
        class="two-option mb-1"
         :options="optionsDashboard" optionValue="value" optionLabel="text" />
        <!-- <payments-table-dashboard v-if="filtersDashboard === 'payments'" /> -->
        <payment-graphs v-if="filtersDashboard === 'payments'" />
        <user-graphs v-if="filtersDashboard === 'country'" />
    </div>
</template>

<script>
import UserGraphs from './UserGraphs/UserGraphs'
import PaymentGraphs from './PaymentGraphs/PaymentGraphs'
    export default {
        name: 'ComparisonsDashboard',
        components:{
            PaymentGraphs,
            UserGraphs,
        },
        data() {
            return {
                optionsDashboard: [
                {
                  text: this.$t('country'),
                  value: 'country'
                },
                {
                  text: this.$t('payments'),
                  value: 'payments'
                },
                

                ],
                filtersDashboard: 'country',
            }
        },
        computed:{
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>