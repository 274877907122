<template>
  <Card class="card-custom">
    <template #content>
        <payments-deposit-graph :option="optionForGraphs" :dataGraph="depositDataToToGraph" />
        <payments-withdraw-graph :option="optionForGraphs" :dataGraph="withdrawDataToToGraph" />
    </template>
    </Card>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentsDepositGraph from './PaymentsDepositGraph'
import PaymentsWithdrawGraph from './PaymentsWithdrawGraph'
    export default {
        components:{
            PaymentsDepositGraph,
            PaymentsWithdrawGraph
        },
        name: 'PaymentGraphs',
        data() {
            return {
                optionForGraphs: {
                stacked: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                }
              }
            }
        },
        computed: {
            ...mapGetters({
                payInfo: 'dashboard/getDashboardPaymentsTableInfo',
              }),
            paymentsInfo(){
              const paymentsInfo = this.payInfo.payments_by_payments_system;
                return paymentsInfo
            },
            depositDataToToGraph() {
      const labels = [];
      const datasets = [];
      let colorIndex = 0;
      this.payInfo.payments_by_ps.forEach((paymentSystem, index) => {
        paymentSystem.payments_graph.forEach(entry => {
          if (!labels.includes(entry.created_at)) {
            labels.push(entry.created_at);
          }
        });
        // deposits data
        datasets.push({
          label: `${paymentSystem.ps_name}`,
          fill: false,
          borderColor: this.getRandomColor(colorIndex++), // get random color
          yAxisID: 'y',
          tension: 0.4,
          data: paymentSystem.payments_graph.map(entry => entry.deposits_by_payment_system_count)
        });

        // withdraws_by_payment_system_count
      });

      return {
        labels: labels,
        datasets: datasets
      };
    },
            withdrawDataToToGraph() {
      const labels = [];
      const datasets = [];
      let colorIndex = 0;
      this.payInfo.payments_by_ps.forEach((paymentSystem, index) => {
        paymentSystem.payments_graph.forEach(entry => {
          if (!labels.includes(entry.created_at)) {
            labels.push(entry.created_at);
          }
        });
        // deposits data
        datasets.push({
          label: `${paymentSystem.ps_name}`,
          fill: false,
          borderColor: this.getRandomColor(colorIndex++), // get random color
          yAxisID: 'y',
          tension: 0.4,
          data: paymentSystem.payments_graph.map(entry => entry.withdraws_by_payment_system_count)
        });

        // withdraws_by_payment_system_count
      });

      return {
        labels: labels,
        datasets: datasets
      };
    }
        },
        methods: {
            
            getRandomColor(index) {
          const hueStep = 30; 
          const hue = (index * hueStep) % 360;
          return `hsl(${hue}, 70%, 50%)`;
        }
        },
    }
</script>

<style lang="scss" scoped>

</style>