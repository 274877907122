<template>
    <div>
        <v-row>
            <v-col xl="6" md="6" sm="12">
        <Card class="card-custom">
            <template #title>
                {{ $t('top_games') }}
            </template>
            <template #content>
                
                <top-games />
            </template>
          </Card>
            </v-col>
            <v-col xl="6" md="6" sm="12">
        <Card class="card-custom">
            <template #title>
                {{ $t('top_provider') }}
            </template>
            <template #content>
               
                <top-providers />
            </template>
          </Card>
            </v-col>
            </v-row>
            <v-row>
                <v-col class="col" xl="6" md="6" cols="12">
                    <Card class="card-custom">
                        <template #title>
                            {{ $t('top_players') }}
                        </template>
                        <template #content>
                            
                            <top-players />
                        </template>
                      </Card>
                </v-col>
            </v-row>
    </div>
</template>

<script>
import TopGames from './TopGames'
import TopProviders from './TopProviders'
import TopPlayers from './TopPlayers'
    export default {
        components:{
            TopPlayers,
            TopProviders,
            TopGames,
        },
        name: 'TopDashboard'
    }
</script>

<style lang="scss" scoped>

</style>