<template>
    <Toolbar>
        <template #start>
              <!-- <h2 v-if="selectedStat"> 
                {{getGraphTitle }}
              </h2> -->
          </template>
          <template #end>
            <v-col class="filters-column pa-0 justify-end">
            <!-- <Dropdown v-model="filters.currency" :options="filteredCurrencies"
                  :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
                </Dropdown> -->
            <!-- <Calendar :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" :showIcon="true" /> -->
    <!-- <Dropdown v-model="filters.group_by" :options="timePeriods"
          :placeholder="$t('time_period')" optionValue="value" optionLabel="text" :emptyFilterMessage="$t('no_data')">
        </Dropdown> -->
        <Dropdown 
            class="custom-dropdown fz"
            :disabled="loadingFlag"
            v-model="filters.currency" :options="filteredCurrencies"
            @change="getDashboardByPeriod"
            :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
          </Dropdown>
        <v-menu
              v-model="calendarMenu"
              attach="#selectButton"
              :close-on-content-click="false"
              transition="scale-transition"
            >
            <template v-slot:activator="{ on, attrs }">
              <div id="selectButton">
                <SelectButton
                
                :disabled="loadingFlag" v-model="filtersDashboard" @input="changePeriod($event)" :options="optionsDashboard" optionValue="value" optionLabel="text" />
              </div>
            </template>
            <div class="input-menu__menu">
              <InputText type="text" readonly class="p-inputtext-sm" style="" :placeholder="$t('date')"
              v-model="getFormattedDate">
            </InputText>
            <Calendar class="custom-calendar" :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon"
            dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
            <Button @click="getDashboardByPeriod" :disabled="isDisabledApplyCalendar" :loading="loadingFlag" :label="$t('applyLabel')" class="p-button-sm p-button-success" />
          </div>
        </v-menu>  
        
        <!-- <v-menu v-model="rangeMenu" :close-on-content-click="false" transition="scale-transition" offset-y
    nudge-left="0" nudge-right="0">
    <template v-slot:activator="{ on }">
      <span v-on="on" class="p-float-label p-input-icon-right">
        <i class="pi pi-schedule" />
        <InputText type="text" readonly class="p-inputtext-sm" placeholder="Date">
        </InputText>
      </span>
    </template>
    <div class="input-menu__menu">
      <date-range-picker @submit="updateRanges" />
    </div>
  </v-menu> -->
        </v-col>
          </template>
        </Toolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
    export default {
        name: 'FiltersDashboard',
        watch:{
          filtersDashboard(newValue, oldValue){
            // console.log(newValue);
            if (newValue === 'custom') {
              this.calendarMenu = true;
            }
          },
          datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.setFilters()
            }
          },
        },
        data() {
            return {
              loadingFlag: true,
                calendarMenu: false,
                optionsDashboard: [
                {
                  text: this.$t('today'),
                  value: 'today'
                },
                {
                  text: this.$t('week'),
                  value: 'week'
                },
                {
                  text: this.$t('month'),
                  value: 'month'
                },
                {
                  text: this.$t('previous_month'),
                  value: 'prev_month'
                },
                {
                  text: this.$t('three_month'),
                  value: 'three_month'
                },
                {
                  text: this.$t('half_year'),
                  value: 'half_year'
                },
                {
                  text: this.$t('custom'),
                  value: 'custom'
                },
                

                ],
                filtersDashboard: 'today',
                today: new Date(),
                datesToFilter: [],
                filters:{
                  currency: 'USD',
                },
                timePeriods:[
                  {
                  text: this.$t('day'), value: 'D'
                },
                  {
                  text: this.$t('week'), value: 'W'
                },
                  {
                  text: this.$t('month'), value: 'M'
                },
                  {
                  text: this.$t('year'), value: 'Y'
                }
              ],
            }
        },
        mounted(){
          this.getDashboardByPeriod();
        },
        computed: {
          ...mapGetters({
              currencies: 'getCurrencies',
          }),
          isDisabledApplyCalendar(){
            return this.loadingFlag || !this.datesToFilter[1]
          },
          filteredCurrencies(){
            return this.currencies.filter(cur => cur !== 'ALL');
          },
          getFormattedDate(){
            if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = moment(dateStr).format('YYYY-MM-DD');
            return date;
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter[0] && this.datesToFilter[1]) {
            // both data
            return `${this.$t('from')} ${formattedDateOne} ${this.$t('to')} ${formattedDateTwo}`;
          } else if (this.datesToFilter[0]) {
            // only one
            return `${this.$t('from')} ${formattedDateOne}`;
          } else{
            return '';
          }
        }
          },
            selectedStat(){
                return this.$store.state.dashboard.selectedStat;
            },
            doNotRepeatInterval(){
                return this.$store.state.dashboard.doNotRepeatInterval;
            },
            getGraphTitle(){
            switch (this.selectedStat) {
              case 'all_players':
                return this.$t('all_players');
              case 'all_deposits':
                
                return this.$t('deposits');
              case 'fin_trans':
                return this.$t('financial_transactions');
            
              default:
                break;
            }
          },
        },
        methods: {
          async getDashboardByPeriod(){
            if (this.filtersDashboard === 'custom' && !this.filters.from_date && !this.filters.to_date) {
              this.$toast.add({ severity: 'error', summary: this.$t('need_select_dates'), life: 4000 }) 
              this.calendarMenu = true;
              return;
            }
            this.calendarMenu = false;
            this.loadingFlag = true;
            this.$store.commit('dashboard/SET_LOADING_FLAG', true);
            await this.setFilters();
            await this.$store.dispatch('dashboard/awaitGetDashboardUsersInfo', this.filters)
            await this.$store.dispatch('dashboard/awaitGetDashboardPaymentsInfo', this.filters)
            await this.$store.dispatch('dashboard/awaitGetDashboardGamesInfo', this.filters)
            await this.$store.dispatch('dashboard/awaitGetDashboardOnlineInfo');
            this.loadingFlag = false;
            this.$store.commit('dashboard/SET_LOADING_FLAG', false);
          },
          changePeriod(value){
            // console.log(value);
            if(value === 'custom'){
              this.calendarMenu = true;
            } else{
              this.datesToFilter = [];
              this.$delete(this.filters, 'from_date');
              this.$delete(this.filters, 'to_date');
              
            }
            if (this.filtersDashboard === 'custom') {
              return;
            } else if(this.doNotRepeatInterval === value){
              return;
            } 
            this.getDashboardByPeriod();
          },
          async setFilters(){
            if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.from_date = formattedDateOne;
            this.filters.to_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.from_date = formattedDateOne;
          }
        }
        const userTimezone = moment.tz.guess();
        const offset = moment.tz(userTimezone).format('Z');
        this.filters.tz_offset = offset;
          this.filters.interval_type = this.filtersDashboard;
          },
        },
    }
</script>

<style lang="scss" scoped>
.input-menu {
  position: relative;

  &__menu {
    display: flex;
    flex-direction: column;
    background-color: var(--surface-a);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;

    .p-component {
      &:not(:last-child) {

        margin-bottom: 5px;
      }
    }
  }
}
</style>