<template>
    <v-row class="mt-1">
        <v-col md="9" cols="12" class="col">
        <Card class="card-custom h-100">
        <template #title>
          <!-- <date-filters-chart /> -->
        </template>
        <template #content>
                <div class="graphs d-flex align-start justify-center">
                  <div class="graph column-two">
                  
                      <dash-line-chart />  
                    </div>
                    <div class="graph column-two">
                        
                        <dash-bar-chart />  
                    </div>
                  
                </div>
        </template>
          </Card>
        </v-col>
        <v-col md="3" cols="12" class="col">
                  <dash-pie-chart />  
        </v-col>
        </v-row>
</template>

<script>
// import DateFiltersChart from './DateFiltersChart'
import DashBarChart from './DashBarChart'
import DashLineChart from './DashLineChart'
import DashPieChart from './DashPieChart'
    export default {
        components:{
            // DateFiltersChart,
            DashBarChart,
            DashLineChart,
            DashPieChart,
        },
        name: 'AllChart',
    }
</script>

<style lang="scss" scoped>
.graphs{
    margin: -5px;
    @media (max-width:1100px){
        flex-wrap: wrap;
    }
    .graph{
      padding: 5px;
      &._size{
      flex: 0 1 500px;
      }
      .p-chart{
  
      }
    }
  }
</style>