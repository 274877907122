<template>
    <div>
        <SelectButton :disabled="loadingFlag" 
        v-model="filtersDashboard"
        class="mb-1"
         :options="optionsDashboard" optionValue="value" optionLabel="text" />
         <Card class="card-custom">
            <template #content>
         <users-map-dashboard v-if="filtersDashboard === 'map'" />
        <by-date-info-graph
        v-if="filtersDashboard === 'by_date'"
        :dataGraph="dataByDateInfo"
        :option="optionForGraphs"
        />
        <by-country-info-graph
        v-if="filtersDashboard === 'by_country'"
        :dataGraph="dataByCountryInfo"
        :option="optionForGraphs"
        />
        </template>
        </Card>
    </div>
</template>

<script>
import UsersMapDashboard from './UsersMapDashboard'
import ByDateInfoGraph from './ByDateInfoGraph'
import ByCountryInfoGraph from './ByCountryInfoGraph'
import {mapGetters} from 'vuex'
    export default {
        components:{
            ByDateInfoGraph,
            ByCountryInfoGraph,
            UsersMapDashboard,
        },
        name: 'UserGraphs',
        data() {
            return {
                optionForGraphs: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            optionsDashboard: [
                {
                  text: this.$t('map'),
                  value: 'map'
                },
                {
                  text: this.$t('total_by_date'),
                  value: 'by_date'
                },
                {
                  text: this.$t('total_by_country'),
                  value: 'by_country'
                },
                

                ],
                filtersDashboard: 'map',
            }
        },
        computed: {
            ...mapGetters({
                generalInfo: 'dashboard/getDashboardUsersInfo',

            }),
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
            byCountryInfo(){
                return this.generalInfo.users_by_countries
            },
            byDateInfo(){
                return this.generalInfo.graph_users
            },
            dataByDateInfo() {
      const labels = [];
      const ftdData = [];
      const usersData = [];
      if (this.byDateInfo) {
      this.byDateInfo.forEach(entry => {
        labels.push(entry.created_at);
        ftdData.push(entry.ftd_by_date_count);
        usersData.push(entry.users_by_date_count);
      });
    }
      return {
        labels: labels,
        datasets: [
            {
                label: this.$t('total_users_count'),
                backgroundColor: '#8e24aa',
                data: usersData
            },
            {
              label: this.$t('total_ftd_count'),
              backgroundColor: '#b339d4',
              data: ftdData
            },
        ]
      };
    },
            dataByCountryInfo() {
      const labels = [];
      const ftdData = [];
      const usersData = [];
      if (this.byCountryInfo) {
      this.byCountryInfo.forEach(entry => {
        labels.push(entry.country_code);
        ftdData.push(entry.total_ftd_by_country_count);
        usersData.push(entry.total_users_by_country_count);
      });
    }
      return {
        labels: labels,
        datasets: [
            {
                label: this.$t('total_users_count'),
                backgroundColor: '#8e24aa',
                data: usersData
            },
            {
              label: this.$t('total_ftd_count'),
              backgroundColor: '#b339d4',
              data: ftdData
            },
        ]
      };
    },
        },
        methods: {
            
            getRandomColor(index) {
          const hueStep = 30; 
          const hue = (index * hueStep) % 360;
          return `hsl(${hue}, 70%, 50%)`;
        }
        },
    }
</script>

<style lang="scss" scoped>

</style>