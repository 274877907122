import { render, staticRenderFns } from "./StatsDeposits.vue?vue&type=template&id=fa27614a&scoped=true"
import script from "./StatsDeposits.vue?vue&type=script&lang=js"
export * from "./StatsDeposits.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa27614a",
  null
  
)

export default component.exports