<template>
    <div>
        <SelectButton class="text-left mb-1" :disabled="loadingFlag" v-model="typeFilters" :options="typeOptions" optionValue="value" optionLabel="text" />
        <div class="wrapper">
            <template v-if="!loadingFlag"> <!--  -->
           <div class="wrapper-item" v-for="(info, index) in getDataByType" :key="index"
           
           @click="toOnlineUsers(info.title)"
           >
                
                    <div class="item"  :class="getInfoProfit(info)">
                        <div class="item__content">
                            <div class="item__title">
                                <span>{{ $t(info.title) }}</span>
                            </div>
                                <Badge v-if="info.badgeText" 
                                value="?" severity="info" class="info-badge ml-1" v-tooltip.top="info.badgeText"></Badge>
                        </div>
                        <div class="item__footer">
                            <div class="item__value">
                                {{ info.currency ? `${info.value} ${info.currency}` : info.value }}
                            </div>
                            <template v-if="info.title !== 'total_users_online_count'">
                                <div class="item__arrow" v-if="info.value !== 0">
                                    <v-icon>{{ getArrowDirection(info) }}</v-icon>
                                </div>
                            </template>
                            <div
                                v-else
                                class="mr-2"
                                style="cursor: pointer;"
                                :class="info.title === 'total_users_online_count' ? getOnlineData(info.value) : ''">
                                </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="wrapper-item" v-for="i in 4">
                    <Skeleton 
                    :key="i"
                    class="item" height="4rem"></Skeleton>
                </div>
            </template>
            </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'TotalTypesDashboard',
        data() {
            return {
                typeOptions: [
                {
                  text: this.$t('totals'),
                  value: 'totals'
                },
                // {
                //   text: this.$t('agent'),
                //   value: 'agent'
                // },
                {
                  text: this.$t('affiliates'),
                  value: 'affiliates'
                },
                {
                  text: this.$t('bonuses'),
                  value: 'bonus'
                },
                

                ],
                typeFilters: 'totals',
            }
        },
        computed:{
            ...mapGetters({
                totalsUsersInfo: 'dashboard/getDashboardTotalsUsersInfo',
                totalsPaymentsInfo: 'dashboard/getDashboardTotalsPaymentsInfo',
                totalsOnlineInfo: 'dashboard/getDashboardOnlineInfo',
            }),
            loadingFlag(){
                return this.$store.state.dashboard.loadingFlag;
            },
            totalsUsersData() {
    const totalsUsersInfo = this.totalsUsersInfo;
    return Object.keys(totalsUsersInfo)
    .filter(key => key === 'total_users_count'
    || key === 'total_users_by_currency_count'


    )
    .map(key => {
      return {
        title: key,
        value: totalsUsersInfo[key]
      };
    });
  },
            totalsPaymentsData() {
    const totalsPaymentsInfo = this.totalsPaymentsInfo;
    const currency = totalsPaymentsInfo.currency;
    return Object.keys(totalsPaymentsInfo)
        .filter(key => key !== 'currency' 
        && key === 'total_users_balance_sum'
        || key === 'total_users_bonus_balance_sum'
    
    ) 
        .map(key => {
            return {
                title: key,
                value: totalsPaymentsInfo[key],
                currency: currency 
            };
        });
  },
    
        totalOnlineData() {
    const totalOnlineData = this.totalsOnlineInfo;
    return Object.keys(totalOnlineData)
        .map(key => {
            return {
                title: key,
                value: totalOnlineData[key],
            };
        });
  },
  getDataByType(){
    const type = this.typeFilters;
    if (type === 'totals') {
        return this.mergedTotalsData;
    } else{
        return null;
    }
  },
  mergedTotalsData() {
    const totalsUsersData = this.totalsUsersData;
    const totalsPaymentsData = this.totalsPaymentsData;
    const totalOnlineData = this.totalOnlineData;
    return [...totalOnlineData, ...totalsUsersData, ...totalsPaymentsData];
  }
        },
        methods:{
            toOnlineUsers(clickedBy){
                if (clickedBy === 'total_users_online_count') {
                    this.$router.push({
                        name: 'players',
                        params: {
                            online: true
                        },
                    })
                    
                }
            },
            getOnlineData(value){
        if (value > 0) {
            return 'online-circle is-online'
        } else{
            return 'online-circle'
        }
    },
            getInfoProfit(item){
                const profit = item.value;
                // console.log(profit);
                if (profit > 0) {
                    return 'is-profit'
                } else if(profit < 0){ 
                    return 'is-expenses'
                }
            },
            getArrowDirection(item){
                const itemValue = item.value;
                if (itemValue > 0) {
                    return 'mdi-menu-up'
                } else {
                    return 'mdi-menu-down'

                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.wrapper{
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}
.wrapper-item{
    padding: 5px;
    display: flex;
    flex: 0 1 50%;
}
.item{
    width: 100%;
    min-width: 275px;
    padding: 5px;
    padding-left: 8px;
    border: 1px solid var(--gray-500);
    border-left: 5px solid var(--gray-500);
    box-shadow: 0 0 5px rgba(125, 8, 187, 0.3);
    border-radius: 6px;
    //display: flex;
    //align-items: flex-start;
    //flex-direction: column;
    &.is-profit {
        border-color: var(--green-500);
        .v-icon{
            color: var(--green-500);
        }
    }
    &.is-expenses{
        border-color: var(--red-500);
        .v-icon{
            color: var(--red-500);
        }
    }
    &__content{
        text-align: left;
    }
    &__title{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__footer{
        display: flex;
        align-items: center;
        flex: 1 1 100%;
    }
    &__value{
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
        flex: 1 1 100%;
    }
    &__arrow{

    }
}
</style>