<template>
    <Card class="card-custom" v-resize="onResize">
        <template #title>
          <div class="d-flex justify-start align-center">
            <span>{{$t('pie_graph_descr')}}</span>
            <v-spacer></v-spacer>
            <Button :label="$t('show_detailed_info')" class="p-button-sm p-button-success" />
          </div>
        </template>
        <template #content>
          <Chart type="pie" ref="DonutChart" :height="250" :width="250" :data="chartData" /> 
        </template>
      </Card>
</template>

<script>
    export default {
        name: 'DashPieChart',
        data() {
            return {
                chartData: {
                labels: [this.$t('online'),this.$t('banned'),this.$t('new_p')],
                datasets: [
                    {
                        data: [300, 50, 100],
                        backgroundColor: [
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"
                        ],
                        hoverBackgroundColor: [
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"
                        ]
                    }
                ]
            },
            }
        },
        mounted() {
          this.$refs.DonutChart.reinit();
        },
        methods: {
          onResize(){
            this.$refs.DonutChart.reinit();
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>