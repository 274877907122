<template>
    
            <div>
                <div class="text-left">
                    {{ $t('reg_by_country') }}
                </div>
                <MapChart
      :countryData="getCountryData"
      highColor="#8e24aa"
      lowColor="#b339d4"
      countryStrokeColor="#9C9C9C"
      defaultCountryFillColor="#B9B9B9"
      />
            </div>
</template>

<script>
import MapChart from 'vue-map-chart'
import { mapGetters } from 'vuex';
    export default {
        components:{
            MapChart,
        },
        name: 'UsersMapDashboard',
        computed: {
            ...mapGetters({
                totalInfo: 'dashboard/getDashboardUsersInfo'
            }),
            usersForMap(){
                return this.totalInfo.users_by_countries;
            },
            getCountryData() {
      const countryData = {};
      if (this.usersForMap && this.usersForMap.length !== 0) {
        
          this.usersForMap.forEach(user => {
            countryData[user.country_code] = Number(user.total_users_by_country_count);
            // `${this.$t('total')}: ${Number(user.total_users_by_country_count)}\n\n FTD: ${user.total_ftd_by_country_count}`
        });
          this.holdData = countryData;
      } 
      return countryData;
    }
        },
        data() {
            return {
            }
        },
    }
</script>

<style lang="scss">
.vue-map-legend{
    border-radius: 6px;
}
</style>
